<template>
  <div
    class="flex h-[39px] items-center gap-x-5 border-[color:var(--color-primary)] bg-[color:var(--color-header-top-bg)] px-5 text-sm text-[color:var(--color-header-top-text)] xl:px-12"
  >
    <!-- OPUS -->
    <template v-if="isPrivateSector">
      <VcTooltip class="w-2" placement="bottom-start" strategy="fixed">
        <template #trigger>
          <VcIcon class="text-success" name="lock-closed" :size="18" />
        </template>

        <template #content><span class="m-2">Private sector</span> </template>
      </VcTooltip>
      <span class="ml-2 h-5 w-px bg-primary" />
    </template>

    <!-- OPUS -->
    <OpLocationSelector v-if="isAuthenticated" :offset-options="0" show-arrow />

    <LanguageSelector v-if="$context.availableLanguages && $context.availableLanguages.length > 1" />
    <CurrencySelector v-if="$context.availableCurrencies && $context.availableCurrencies.length > 1" class="h-full" />

    <div class="ms-auto flex items-center">
      <!-- Call us block -->
      <!-- <div v-if="support_phone_number" class="flex items-center">
        <VcIcon class="me-1.5 fill-primary" name="phone" size="sm" />

        <span class="mr-1 font-thin">
          {{ $t("shared.layout.header.top_header.call_us_label") }}
        </span>

        <a
          class="py-1 font-bold text-[--header-top-link-color] hover:text-[--header-top-link-hover-color]"
          :href="`tel:${support_phone_number}`"
        >
          {{ support_phone_number }}
        </a>
        <span class="mx-4 h-5 w-px bg-primary" />
      </div> -->

      <!-- OPUS -->
      <!-- Email us block -->
      <!-- <div v-if="$cfg.support_email" class="flex items-center">
        <a
          class="align-center flex flex-row py-1 font-semibold text-[color:var(--color-header-top-link)] hover:text-[color:var(--color-header-top-link-hover)]"
          :href="`mailto:${$cfg.support_email}`"
        >
          <i class="fas fa-envelope mr-1.5 mt-1.5 text-primary" />

          <span>{{ $cfg.support_email }}</span>
        </a>

        <span class="mx-4 h-5 w-px bg-primary" />
      </div> -->
      <!-- !OPUS -->

      <!-- Authorized menu items -->
      <template v-if="isAuthenticated">
        <TopHeaderLink v-if="false" to="/account/orders">
          {{ $t("shared.layout.header.top_header.link_dashboard") }}
        </TopHeaderLink>

        <template v-if="isPrivateSector">
          <TopHeaderLink to="/home">
            {{ $t("shared.layout.header.top_header.home") }}
          </TopHeaderLink>
          <span class="mx-4 h-5 w-px bg-primary" />
          <TopHeaderLink to="/catalog">
            {{ $t("shared.layout.header.top_header.private_catalog") }}
          </TopHeaderLink>
          <span class="mx-4 h-5 w-px bg-primary" />
        </template>

        <span v-if="false" class="mx-2 size-1 rounded-full bg-primary" />
        <!-- <a
          v-t="'shared.layout.header.top_header.about'"
          target="_blank"
          href="/about-us"
          class="py-1 font-bold text-[color:var(--color-header-top-link)] hover:text-[color:var(--color-header-top-link-hover)]"
        /> -->

        <span
          class="flex cursor-pointer items-center py-1 font-bold text-[color:var(--color-header-top-link)] hover:text-[color:var(--color-header-top-link-hover)]"
          tabindex="0"
          role="button"
          @click="openContactUsModal"
          @keydown.enter="openContactUsModal"
        >
          <VcIcon class="mr-1.5 mt-0.5 text-primary-100" name="mail" :size="14" />
          {{ $t("shared.opus.contact_us_modal.title") }}
        </span>

        <span class="mx-4 h-5 w-px bg-primary" />

        <a
          v-t="'shared.layout.header.top_header.faq'"
          target="_blank"
          href="/faq"
          class="py-1 font-bold text-[color:var(--color-header-top-link)] hover:text-[color:var(--color-header-top-link-hover)]"
        />

        <TopHeaderLink v-if="false" to="/contact">
          {{ $t("shared.layout.header.top_header.link_contact_us") }}
        </TopHeaderLink>

        <span class="mx-4 h-5 w-px bg-primary" />

        <!-- Account menu -->
        <div ref="loginMenu" class="relative flex flex-row items-center gap-x-1">
          <!-- Operator -->
          <template v-if="operator">
            <span class="font-bold">{{ operator.contact?.fullName || operator.userName }}</span>
            <span class="text-neutral-400">
              {{ $t("shared.layout.header.top_header.logged_in_as") }}
            </span>
          </template>

          <button
            type="button"
            class="flex cursor-pointer items-center py-1 text-[--header-top-text-color] hover:text-[--header-top-link-color]"
            @click="loginMenuVisible = !loginMenuVisible"
          >
            <span class="font-bold">
              <template v-if="isMultiOrganization">{{ organization?.name }} /</template>
              {{ user.contact?.fullName || user.userName }}
            </span>

            <VcIcon
              class="ms-1.5 fill-accent-200 [--vc-icon-size:1rem] lg:fill-primary lg:[--vc-icon-size:0.625rem]"
              :name="loginMenuVisible ? 'chevron-up' : 'chevron-down'"
            />
          </button>

          <div
            v-if="loginMenuVisible"
            class="absolute right-0 top-full z-10 flex w-60 flex-col rounded-md bg-additional-50 text-additional-950 shadow-md"
          >
            <div class="flex items-center justify-between p-3">
              <router-link
                to="/account/profile"
                class="flex items-center hover:text-primary"
                @click="loginMenuVisible = false"
              >
                <VcIcon class="fill-primary" name="opus-account-circle" />

                <span class="ml-2">
                  {{ user.contact?.fullName }}
                </span>
              </router-link>

              <div class="grow"></div>

              <VcButton
                :title="$t('shared.layout.header.link_logout')"
                class="ml-4"
                variant="outline"
                color="neutral"
                size="xs"
                icon
                @click="() => signMeOut()"
              >
                <VcIcon name="op-logout" />
              </VcButton>
            </div>

            <div v-if="isMultiOrganization" class="border-t py-3">
              <div class="px-3 py-1 text-xs text-neutral-600">
                {{ $t("common.labels.organizations") }}
              </div>

              <VcRadioButton
                v-for="item in user.contact?.organizations?.items"
                :key="item.id"
                v-model="contactOrganizationId"
                :label="item.name"
                :value="item.id"
                class="flex px-3 py-1 text-sm"
                @change="selectOrganization"
              />
            </div>
          </div>
        </div>
      </template>

      <!-- Unauthorized menu items -->
      <template v-else>
        <a
          v-t="'shared.layout.header.top_header.about'"
          target="_blank"
          href="https://www.omniapartners.com/solutions/opus"
          class="py-1 font-bold text-[color:var(--color-header-top-link)] hover:text-[color:var(--color-header-top-link-hover)]"
        />

        <!-- <TopHeaderLink to="/faq">
          {{ $t("shared.layout.header.top_header.faq") }}
        </TopHeaderLink> -->
        <!-- <span class="mx-4 h-5 w-px bg-primary" /> -->

        <TopHeaderLink v-if="false" to="/contact">
          {{ $t("shared.layout.header.top_header.link_contact_us") }}
        </TopHeaderLink>
        <!-- OPUS -->
        <span v-if="false" class="mx-4 h-5 w-px bg-primary" />

        <TopHeaderLink v-if="false" to="/sign-in">
          {{ $t("shared.layout.header.link_sign_in") }}
        </TopHeaderLink>

        <TopHeaderLink v-if="false" to="/sign-up">
          {{ $t("shared.layout.header.link_register_now") }}
        </TopHeaderLink>
        <!-- /OPUS -->
      </template>
    </div>
  </div>
</template>

<script setup lang="ts">
import { onClickOutside } from "@vueuse/core";
import { ref } from "vue";
import { useModuleSettings } from "@/core/composables/useModuleSettings";
import { MODULE_XAPI_KEYS } from "@/core/constants/modules";
import { useSignMeOut, useUser } from "@/shared/account";
import { useModal } from "@/shared/modal";
import { OpContactUsDialog } from "@/shared/opus/common/components";
import TopHeaderLink from "./top-header-link.vue";
import CurrencySelector from "@/shared/layout/components/currency-selector/currency-selector.vue";
import LanguageSelector from "@/shared/layout/components/language-selector/language-selector.vue";
import OpLocationSelector from "@/shared/opus/common/components/op-location-selector.vue";

const { isAuthenticated, isMultiOrganization, user, operator, organization, isPrivateSector, switchOrganization } =
  useUser();
const { signMeOut } = useSignMeOut();
const { getSettingValue } = useModuleSettings(MODULE_XAPI_KEYS.MODULE_ID);

const loginMenu = ref(null);
const loginMenuVisible = ref(false);
const contactOrganizationId = ref(user.value?.contact?.organizationId);

const support_phone_number = getSettingValue(MODULE_XAPI_KEYS.SUPPORT_PHONE_NUMBER);

async function selectOrganization(): Promise<void> {
  if (!contactOrganizationId.value) {
    return;
  }

  await switchOrganization(contactOrganizationId.value);
  loginMenuVisible.value = false;
}

async function signOut() {
  await signMeOut();
  //OPUS
  location.href = "/sign-in";
  //!OPUS
}

function openContactUsModal() {
  openModal({
    component: OpContactUsDialog,
    props: {
      onResult() {
        closeModal();
      },
    },
  });
}

onClickOutside(loginMenu, () => {
  loginMenuVisible.value = false;
});

// OPUS
const { openModal, closeModal } = useModal();
// !OPUS
</script>
